import React from 'react';

import { TextField } from '../TextField';

type MuiProps = Omit<React.ComponentProps<typeof TextField>, 'type'>;
export interface DatepickerProps extends MuiProps {
  id: string;
  missingValueText?: React.ReactNode;
  minDate?: string;
  maxDate?: string;
}

export const Datepicker: React.VFC<DatepickerProps> = ({
  id,
  minDate,
  maxDate,
  ...rest
}) => {
  return (
    <TextField
      {...rest}
      id={id}
      type="date"
      inputProps={{
        min: minDate ?? `${new Date().getFullYear() - 120}-01-01`,
        max: maxDate ?? `${new Date().getFullYear() + 120}-01-01`,
      }}
    />
  );
};
