import React, { Suspense } from 'react';
import { useIntl } from 'react-intl';

import { TextBody } from 'components/StageLayout/TextBody';
import { PartnerConsent } from 'features/PartnerStage/DataCollection/ConsentCollection/PartnerConsent';

import { AddressField } from '../../../../components/AddressField';
import { AddressHistoryField } from '../../../../components/AddressHistoryField/AddressHistoryField';
import { AliasesField } from '../../../../components/AliasesField/AliasesField';
import { AvailabilityField } from '../../../../components/AvailabilityField';
import { BankField } from '../../../../components/BankField';
import { Checkboxes } from '../../../../components/Checkboxes';
import { Datepicker } from '../../../../components/Datepicker';
import { DropdownField } from '../../../../components/DropdownField';
import { FileUpload } from '../../../../components/FileUpload';
import { HistoryField } from '../../../../components/HistoryField';
import { RadioGroupField } from '../../../../components/RadioGroupField';
import { ShortAnswer } from '../../../../components/ShortAnswer';
import { TextArea } from '../../../../components/TextArea';
import { TextFieldWithConfirm } from '../../../../components/TextFieldWithConfirm/TextFieldWithConfirm';
import { Question } from '../../../Question/Question';
import { InputProps } from './types';

const CountryCodeField = React.lazy(
  () => import('../../../../components/CountryCodeField'),
);
const LicenseDataField = React.lazy(
  () => import('../../../../components/LicenseDataField'),
);
const EmploymentHistoryField = React.lazy(
  () => import('../../../../components/EmploymentHistoryField'),
);
const IbanBicField = React.lazy(
  () => import('../../../../components/IbanBicField'),
);
const TaxIdTextInput = React.lazy(
  () => import('../../../../components/TaxIdTextInput'),
);
const SsnTextInput = React.lazy(
  () => import('../../../../components/SsnTextInput'),
);

interface PlaceholderProps extends InputProps {
  inputName: (key: string) => string;
}

export const Placeholder: React.VFC<PlaceholderProps> = ({
  dataField,
  inputName,
}) => {
  const { type, question, key } = dataField;
  return (
    <label htmlFor={key}>
      {type} - {question}
      <input name={inputName(key)} type="text" />
      DataField not Implemented: {JSON.stringify(dataField)}
    </label>
  );
};

// Following rails naming conventions for form parameters
// https://guides.rubyonrails.org/v6.0/form_helpers.html#understanding-parameter-naming-conventions
const defaultName = (dataKey: string) => {
  return `data_collection[${dataKey}]`;
};

export const Input: React.VFC<InputProps> = ({
  dataField,
  uploadFields,
  showErrors,
  inputName = defaultName,
  dense,
  countryCode,
  applicantFirstName,
  applicantLastName,
  isReachApplicationFormEnabled,
  additionalPartnerEmploymentQuestionsRequired = false,
}) => {
  const intl = useIntl();

  // Options is an any type in the database, we'll have to assert the type per use
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    type,
    question,
    key,
    required,
    hint,
    options,
    extra,
    predefined,
    confirm,
    // eslint-disable-next-line camelcase
    years_of_validation,
    location_country_code: locationCountryCode,
  } = dataField;
  const questionProps = {
    hint,
    id: key,
    question,
    required,
    dense,
    isReachApplicationFormEnabled,
  };

  // Add fallback validation for required answers to exclude whitespace
  // only values. Only applies if no custom validation pattern was
  // provided.
  const fallbackValidationPattern = required ? '.*\\S.*' : undefined;
  const fallbackValidationMessage =
    required && !dataField.format
      ? intl.formatMessage({
          defaultMessage: 'Please enter a value',
          description:
            'Error text for required text input with only whitespace',
        })
      : undefined;

  switch (type) {
    case 'text_area':
      return (
        <Question {...questionProps}>
          <TextArea
            id={key}
            placeholder={intl.formatMessage({
              defaultMessage: 'Please enter text here',
              description: 'Placeholder text for Text Area input',
            })}
            name={inputName(key)}
            required={required}
            shouldValidate={showErrors}
            inputProps={{
              onInput:
                fallbackValidationPattern && fallbackValidationMessage
                  ? event => {
                      const input = event.currentTarget;
                      if (input.value.match(fallbackValidationPattern)) {
                        input.setCustomValidity('');
                      } else {
                        input.setCustomValidity(fallbackValidationMessage);
                      }
                    }
                  : undefined,
            }}
            helperText={fallbackValidationMessage}
          />
        </Question>
      );
    case 'text_field': {
      if (key === 'iban' && predefined) {
        return (
          <Question {...questionProps}>
            <IbanBicField
              id={key}
              inputName={inputName}
              required={required}
              placeholder={intl.formatMessage({
                defaultMessage: 'Enter IBAN number',
                description: 'Placeholder text for predefined IBAN text input',
              })}
              shouldValidate={!!showErrors}
            />
          </Question>
        );
      }

      // bic is extracted from `iban`, so we do not render
      if (key === 'bic' && predefined) {
        return null;
      }

      if (key === 'ssn' && predefined) {
        return (
          <SsnTextInput
            inputName={inputName(key)}
            questionKey={key}
            question={question}
            countryCode={countryCode}
            hint={hint}
            required={required}
          />
        );
      }
      if (key === 'tax_id' && predefined) {
        return (
          <TaxIdTextInput
            questionKey={key}
            question={question}
            countryCode={countryCode}
            hint={hint}
            required={required}
          />
        );
      }
      if (
        ['bank_routing_number', 'bank_account_number'].some(
          k => key === k && predefined,
        )
      ) {
        return (
          <Question {...questionProps}>
            <BankField
              questionKey={key}
              required={required}
              shouldValidate={showErrors}
            />
          </Question>
        );
      }
      if (confirm) {
        return (
          <Question {...questionProps}>
            <TextFieldWithConfirm
              id={key}
              placeholder={intl.formatMessage({
                defaultMessage: 'Please enter text here',
                description: 'Placeholder text for Short Answer input',
              })}
              name={inputName(key)}
              required
              error={showErrors}
              shouldValidate={showErrors}
              pattern={dataField.format ?? fallbackValidationPattern}
              helperText={
                extra.format_validation_message ?? fallbackValidationMessage
              }
            />
          </Question>
        );
      }

      return (
        <div>
          {/* <pre>{JSON.stringify(questionProps)}</pre> */}
          <Question {...questionProps}>
            <ShortAnswer
              id={key}
              placeholder={intl.formatMessage({
                defaultMessage: 'Please enter text here',
                description: 'Placeholder text for Short Answer input',
              })}
              name={inputName(key)}
              required={required}
              shouldValidate={showErrors}
              validationStart={false}
              validationEnd={false}
              pattern={dataField.format ?? fallbackValidationPattern}
              helperText={
                extra.format_validation_message ?? fallbackValidationMessage
              }
            />
          </Question>
        </div>
      );
    }
    case 'availability':
      return (
        <Question {...questionProps}>
          <AvailabilityField
            id={key}
            baseName={inputName(key)}
            required={required}
            shouldValidate={showErrors}
          />
        </Question>
      );
    case 'checkboxes':
      return (
        <Question {...questionProps}>
          <Checkboxes
            id={key}
            baseName={inputName(key)}
            required={required}
            options={options}
            shouldValidate={showErrors}
          />
        </Question>
      );
    case 'datepicker':
      return (
        <Question {...questionProps}>
          <Datepicker
            id={key}
            required={required}
            shouldValidate={showErrors}
            name={inputName(key)}
            minDate={extra?.min_date}
            maxDate={extra?.max_date}
          />
        </Question>
      );
    case 'dropdown':
      return (
        <Question {...questionProps}>
          <DropdownField
            id={key}
            required={required}
            options={options}
            name={inputName(key)}
            placeholder={intl.formatMessage({
              defaultMessage: 'Search',
              description: 'Placeholder text a searchable input',
            })}
            shouldValidate={showErrors}
            valueMissingText={intl.formatMessage({
              defaultMessage: 'Please select an option',
              description:
                'Message shown if a user does not select anything from the dropdown.',
            })}
          />
        </Question>
      );
    case 'country_code':
      return (
        <Question {...questionProps}>
          <CountryCodeField
            id={key}
            required={required}
            inputName={inputName(key)}
            shouldValidate={showErrors}
          />
        </Question>
      );
    case 'radio':
      return (
        <Question {...questionProps}>
          <RadioGroupField
            id={key}
            required={required}
            options={options}
            name={inputName(key)}
            shouldValidate={showErrors}
            defaultValue={
              key === 'phone_platform'
                ? 'whats_app'
                : options?.find(option => option.default)?.value
            }
          />
        </Question>
      );
    case 'address':
      return (
        <Question {...questionProps}>
          <AddressField
            inputName={inputName}
            id={key}
            required={required}
            shouldValidate={showErrors}
            locationCountryCode={locationCountryCode}
          />
        </Question>
      );
    case 'history_address':
      return (
        <Question {...questionProps}>
          <HistoryField
            inputName={inputName}
            id={key}
            required={required}
            shouldValidate={showErrors}
            // eslint-disable-next-line camelcase
            yearsOfValidation={String(years_of_validation)}
            childField="address"
            dataFieldId={dataField.id}
            uploadFields={uploadFields}
            allowFileUpload={dataField.extra.allow_file_upload}
            fileUploadRequired={dataField.extra.file_upload_required}
            fileUploadHint={dataField.extra.file_upload_hint}
          />
        </Question>
      );
    case 'history_employment':
      return (
        <Question {...questionProps}>
          <HistoryField
            inputName={inputName}
            id={key}
            uploadFields={uploadFields}
            required={required}
            shouldValidate={showErrors}
            // eslint-disable-next-line camelcase
            yearsOfValidation={String(years_of_validation)}
            childField="employment"
            dataFieldId={dataField.id}
            allowFileUpload={dataField.extra.allow_file_upload}
            fileUploadRequired={dataField.extra.file_upload_required}
            fileUploadHint={dataField.extra.file_upload_hint}
            additionalPartnerEmploymentQuestionsRequired={
              additionalPartnerEmploymentQuestionsRequired
            }
          />
        </Question>
      );
    case 'address_history':
      return (
        <Question {...questionProps}>
          <AddressHistoryField
            inputName={inputName}
            id={key}
            required={required}
            shouldValidate={showErrors}
          />
        </Question>
      );
    case 'aliases':
      return (
        <Question {...questionProps}>
          <AliasesField
            inputName={inputName}
            id={key}
            required={required}
            shouldValidate={showErrors}
          />
        </Question>
      );
    case 'employment_history':
      return (
        <Question {...questionProps}>
          <EmploymentHistoryField
            inputName={inputName}
            id={key}
            required={required}
            shouldValidate={showErrors}
          />
        </Question>
      );
    case 'license_data':
      return (
        <Question {...questionProps}>
          <LicenseDataField
            inputName={inputName}
            id={key}
            required={required}
            shouldValidate={showErrors}
          />
        </Question>
      );
    case 'file':
    case 'resume':
      if (!uploadFields) {
        throw new Error(
          'upload field config must be present when a file input is present',
        );
      }
      return (
        <Question {...questionProps}>
          <FileUpload
            id={key}
            required={required}
            name={inputName(key)}
            uploadFields={uploadFields}
            shouldValidate={showErrors}
            dataFieldId={dataField.id}
          />
        </Question>
      );
    case 'consent':
      return (
        <Question
          {...questionProps}
          required
          hint={
            <TextBody
              className="partner-consent-body"
              customContent={hint}
              fallback=""
            />
          }
        >
          <PartnerConsent
            id={key}
            required={required}
            inputTypes={dataField.extra.inputs ?? []}
            shouldValidate={showErrors}
            applicantFirstName={applicantFirstName}
            applicantLastName={applicantLastName}
          />
        </Question>
      );
    case 'hidden_field':
      return (
        <input
          type="hidden"
          id={key}
          value={extra?.hidden_value}
          name={inputName(key)}
        />
      );
    default:
      return (
        <Placeholder dataField={dataField} key={key} inputName={inputName} />
      );
  }
};

export const FormInput: React.VFC<InputProps> = props => {
  return (
    <Suspense fallback={<div />}>
      <Input {...props} dataField={props.dataField} />
    </Suspense>
  );
};
